<template>
  <div>
    <DepositForm />
  </div>
</template>

<script >
import DepositForm from './depositForm.vue'

export default {
  components: { DepositForm },
}
</script>
